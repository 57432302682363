import styled from 'styled-components';

export const PageTitle = styled.h1`
  margin-top: 2rem;
`

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  margin-bottom: 2rem;

  @media(max-width: 871px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const GalleryImageWrapper = styled.div`
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);

  &:hover{
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);
    transition: box-shadow .1s ease-out .1s;
  }
`
