import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ContentSafeArea } from '../../components/globalComponents';
import { Gallery, GalleryImageWrapper, PageTitle } from './styles';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

export default function Servicos() {

  const { allImages } = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
        edges {
          node {
            name
            childImageSharp {
              thumb: fluid(maxWidth: 270, maxHeight: 270) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              full: fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
          }
        }
      }
    }
  `)

  return(
    <SimpleReactLightbox>
    <Layout>
      <SEO title="Portfolio" />
      <ContentSafeArea>
        <PageTitle>Nossos trabalhos</PageTitle>
        <SRLWrapper>
          <Gallery>
            {allImages.edges.map(({node}) =>
              <GalleryImageWrapper>
                <a href={node.childImageSharp.full.src} data-attribute="SRL">
                  <Img
                    fluid={node.childImageSharp.thumb}
                  />
                </a>
              </GalleryImageWrapper>
            )}
          </Gallery>
        </SRLWrapper>
      </ContentSafeArea>
    </Layout>
    </SimpleReactLightbox>
  )
}
